import * as React from "react"
import { Link } from "gatsby"


// markup
const NotFoundPage = () => {
  return (
    <>
    </>
  )
}

export default NotFoundPage
